import React from 'react';
// import config from '../../config';
// import { Link } from 'gatsby';

export default function Pitch() {
    return (<><section id="first">
        <header>
            <h2>Nos apprenant·es mettent les doigts dans la prod' !</h2>
        </header>
        <div className="content">
            <h3 id="des-labs-devops">Des labs DevOps…</h3>
            <p>
                Pour s’adapter à la complexité croissante de l’informatique, le mouvement DevOps encourage l’expérimentation systématique dans des conditions réalistes : on parle de faire des “labs”.
          </p>
            <p>
                Chez Uptime nous pensons que ce principe technique doit aussi
                être mis au centre de la formation professionnelle pour assurer un
                apprentissage pertinent, opérationnel et captivant.
          </p>
            <h3 id="pour-gagner-en-autonomie">…pour gagner en autonomie</h3>
            <p>
                Basées sur des travaux pratiques documentés et reproductibles dans des environnement complexes, nos formations permettent d’éviter
                les explications simplistes, déconnectées et la transmission
                verticale.
          </p>
            <p>
                Nous faisons en sorte que les apprenant·es s’approprient nos labs pour
                continuer à approfondir par la suite. À chaque formation, profils
                débutants comme avancés y trouveront leur compte pour aller plus
                loin dans la découverte du vaste écosystème DevOps. Ces outils et
                méthodes accompagneront les ingénieur·es Cloud tout au long de leur
                carrière.
          </p>
            {/* <span className="image main">
            <img src={pic2} alt="" />
          </span> */}
        </div>
    </section>

        <section>
            <header>
                <h2 id="nous-sommes-des-ingénieurs">Nous sommes des ingénieurs !</h2>
            </header>
            <div className="content">
                <p>
                    Au plus proche de vos besoins, techniquement pertinents, les membres
                    d’Uptime sont ingénieurs, curieux et en veille active.
                </p>
                <h3 id="nous-expérimentons-sans-cesse-pour-notre-pédagogie">
                    Nous expérimentons sans cesse pour notre pédagogie
          </h3>
                <p>
                    Nous investissons du temps et des ressources dans la recherche de
                    méthodes pédagogiques efficaces, ludiques et modernes. Nos cours ne
                    sont pas “standards” et nos exemples permettent de voir à l’œuvre et
                    de manipuler les technologies dans leur contexte.
          </p>
            </div>
        </section>
        <section>
            <header>
                <h2>Des formations accessibles avec Coopaname</h2>
            </header>
            <div className="content">
                <p>
                    Nous travaillons avec vous pour adapter nos formats et nos prix en
                    fonction de vos besoins et contraintes. Nous vous assistons dans les
                    échanges avec les différents partenaires de financement (
            <abbr title="Opérateurs de Compétences">OPCO</abbr>, Pôle Emploi,{' '}
                    <abbr title="Compte Personnel de Formation">CPF</abbr>…).
          </p>
                <p>
                    Notre activité a été créée au sein de <a href="https://www.coopaname.coop/article/coopaname-cest-politique">Coopaname</a>, une coopérative parisienne qui défend une vision solidaire de la société et du salariat grâce à une mutuelle de travail associé de plus de 800 membres.
          </p>
            </div>
        </section>
    </>);
}
