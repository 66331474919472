import React from 'react';

import Pitch from '../components/Pitch';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideFooter from '../components/SideFooter';

import CatalogTable from '../components/CatalogTable';

import config from '../../config';

// import heroImage from '../assets/images/logo_full.jpg';
import heroImage from '../assets/images/hero.jpg';
import loreal from '../assets/images/brands/loreal.png';
import orange from '../assets/images/brands/orange.png';
import arianegroup from '../assets/images/brands/arianegroup.png';
import capgemini from '../assets/images/brands/capgemini.png';
import coopaname from '../assets/images/brands/coopaname.png';
// import humancoders from '../assets/images/brands/hc.svg';
import inseec from '../assets/images/brands/inseec.png';
import happydev from '../assets/images/brands/happydev.png';
import esgi from '../assets/images/brands/esgi.jpg';

// import logo from '../assets/images/uptime.png';
// import ContactForm from '../components/ContactForm';
import { Link } from 'gatsby';

const IndexPage = () => (
    <Layout>
        <div id="wrapper">
            <section className="intro">
                <Header />
                <div className="content 
vertically-aligned">
                    <h2 className="subheading">Prendre le temps d'apprendre<br />Démystifier l'informatique moderne<br />L'expérimentation, loin des buzzwords</h2>
                    <span className="image fill heroImage" data-position="center">
                        <img src={heroImage} alt="" />
                    </span>
                </div>
            </section>

            <Pitch />


            <section>
                <header>
                    <h2><Link to="/formations" >Notre catalogue de formations</Link></h2>
                </header>
                <div className="content">
                    <h2 id="nos-formations"><Link to="/formations">Nos formations</Link></h2>
                    <p>
                        <Link to="/formations" >Découvrez notre <strong>catalogue de formations</strong> ainsi que quelques-uns de nos <strong>supports de formation</strong> :</Link>
                    </p>
                    <ul className="actions">                        <li>
                        <Link to="/formations" className="button primary large">
                            Formations Uptime
                        </Link></li>
                        <br />
                        <li>
                            <a href="https://supports.uptime-formation.fr/" className="button primary large">
                                Supports de formations
                            </a>
                        </li>                        <br />

                        <li>
                            <Link to="/catalogue.pdf" className="button primary large">Catalogue (PDF)</Link>
                        </li>
                    </ul>

                    <CatalogTable />

                </div>
            </section>
            <section>
                <header>
                    <h2><Link to="/consulting">Consulting</Link></h2>
                </header>
                <div className="content">
                    <h2 id="consulting"><Link to="/consulting">Consulting</Link></h2>
                    <p>
                        Nous pouvons vous orienter sur l'intégration d'outils de <abbr rel="Intégration continue / Développement continu">CI/CD</abbr> dans vos projets d'infrastructure logicielle et la mise en place d'infrastructures DevOps. Nous effectuons également du missions de sécurité informatique, consulting en architecture réseau et système, audits de sécurité, déploiement sur mesure de solutions de type <abbr rel="Security Operation Center">SOC</abbr>, analyse forensique en cas d'attaque informatique
                    </p>
                    <ul className="actions">
                        <li>
                            <Link to="/consulting" className="button primary large">
                                Consulting Uptime
                            </Link>
                        </li>
                    </ul>

                </div>
            </section>

            <section>
                <header>

                    <h2 id="nous-contacter">Nous contacter</h2>
                    <SideFooter />
                </header>
                <div className="content">
                    <p>Vous êtes intéressé·e par une formation ? Vous souhaitez obtenir des détails, ou encore concevoir une formation sur mesure avec nous ?</p>
                    <h3>Contactez-nous !</h3>
                    <p><a href={`mailto:${config.emailId}`} className="button primary large">Par e-mail : {config.emailId}</a></p>
                    {config.phone && (<p><a href={`tel:${config.phone}`} className="button primary large">Par téléphone : {config.phone}</a></p>)}
                    <h3>Découvrez les formateurs d'Uptime</h3>
                    <p><Link to="/about" className="button primary large">Qui sommes-nous ?</Link></p>
                </div>
            </section>
            <section>
                <header>
                    {/* <h2 id="nous-contacter">Nous contacter</h2> */}
                </header>
                <div className="content">
                    <h2>Nous avons leur confiance</h2>
                    <div className="box alt">
                        <div className="row gtr-uniform gtr-200 vertically-aligned">
                            <div className="col-4">
                                <span className="image fit">
                                    <a href="https://www.ariane.group/fr/"><img src={arianegroup} alt="arianegroup" /></a>
                                </span>
                            </div>
                            <div className="col-4">
                                <span className="image fit">
                                    <a href="https://www.capgemini.com/fr-fr/"><img src={capgemini} alt="capgemini" /></a>
                                </span>
                            </div>
                            <div className="col-4">
                                <span className="image fit">
                                    <a href="http://www.coopaname.coop/"><img src={coopaname} alt="Coopaname" /></a>
                                </span>
                            </div>
                            <div className="col-3">
                                <span className="image fit">
                                    <a href="https://www.esgi.fr/"><img src={esgi} alt="ESGI" /></a>
                                </span>
                            </div>
                            <div className="col-3">
                                <span className="image fit">
                                    <a href="https://happy-dev.fr/fr/"><img src={happydev} alt="Happy-Dev" /></a>
                                </span>
                            </div>
                            {/* <div className="col-3">
                                <span className="image fit">
                                    <a href="https://humancoders.com/"><img src={humancoders} alt="Human Coders" /></a>
                                </span>
                            </div> */}
                            <div className="col-3">
                                <span className="image fit">
                                    <a href="https://www.inseec.com/"><img src={inseec} alt="INSEEC" /></a>
                                </span>
                            </div>

                            {/* université marne la vallée */}

                            <div className="col-4">
                                <span className="image fit">
                                    <a href="https://www.loreal.fr/"><img src={loreal} alt="" /></a>
                                </span>
                            </div>
                            <div className="col-4">
                                <span className="image fit">
                                    <a href="https://www.orange.com/fr/accueil"><img src={orange} alt="" /></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </div>
    </Layout>
);

export default IndexPage;
