import React from 'react';
import Scroll from './Scroll';
// import config from '../../config';
import logo from '../assets/images/uptime_trimmed.png';

export default function Header() {
  return (
    <header>
                <h2>          <Scroll type="id" element="first">
<a href="#first" className="image fit">
                    <img src={logo} alt="Uptime Formation | Formation - Labs - Consulting" />
                  </a></Scroll></h2>
      <ul className="actions">
        <li>
          <Scroll type="id" element="first">
            <a href="#first" className="arrow">
              <span className="label">Next</span>
            </a>
          </Scroll>
        </li>
      </ul>
    </header>
  );
}
